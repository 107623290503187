import { AnswerSheetRow } from './types'

const isReplied = (answerSheetRow?: AnswerSheetRow) => {
  return (
    !!answerSheetRow?.assessmentRequests?.repliedAt &&
    !!answerSheetRow?.teacherFeedback
  )
}

const isCanceled = (answerSheetRow?: AnswerSheetRow) => {
  return !!answerSheetRow?.assessmentRequests?.cancelledAt
}

const isRequested = (answerSheetRow?: AnswerSheetRow) => {
  return !!answerSheetRow?.assessmentRequests?.requestedAt
}

const isPending = (answerSheetRow?: AnswerSheetRow) => {
  return (
    isRequested(answerSheetRow) &&
    !isCanceled(answerSheetRow) &&
    !isReplied(answerSheetRow)
  )
}

export const getCurrentPendingAnswerSheetRow = (
  answerSheetRows?: AnswerSheetRow[]
) => {
  if (!answerSheetRows?.length) {
    return { index: -1, answerSheetRow: null }
  }

  const pendingAnswerSheetRowIndex = answerSheetRows.findIndex(isPending)

  if (pendingAnswerSheetRowIndex < 0) {
    return { index: -1, answerSheetRow: null }
  }

  return {
    index: pendingAnswerSheetRowIndex,
    answerSheetRow: answerSheetRows[pendingAnswerSheetRowIndex],
  }
}

export const getAnswerSheetRowStatus = ({
  answerSheetRows,
  currentAnswerSheetRow,
  pendingAnswerSheetRow,
}: {
  answerSheetRows?: AnswerSheetRow[]
  currentAnswerSheetRow?: AnswerSheetRow
  pendingAnswerSheetRow?: {
    index: number
    answerSheetRow: AnswerSheetRow | null
  }
}) => {
  if (!currentAnswerSheetRow) {
    return {
      isReplied: false,
      isCanceled: false,
      isPending: false,
    }
  }

  const _pendingAnswerSheetRow = pendingAnswerSheetRow
    ? pendingAnswerSheetRow
    : getCurrentPendingAnswerSheetRow(answerSheetRows)

  return {
    isReplied: isReplied(currentAnswerSheetRow),
    isCanceled: isCanceled(currentAnswerSheetRow),
    isPending:
      currentAnswerSheetRow.id === _pendingAnswerSheetRow.answerSheetRow?.id,
  }
}
