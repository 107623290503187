import React from 'react'
import { Icon } from '../icon'
import { setTextToClipboard } from '../../utils/clipboard'
import constructLocale, { LangType } from '../../utils/locale-constructor'
import themeVar from '../../theme/themeVar'

type PaymentMethodTypes = 'CVS' | 'VACC' | 'BNPL'

export interface InfoForPaymentProps {
  lang: LangType
  className?: string
  paymentMethod: PaymentMethodTypes
  bankCode?: string
  expiryDate: string
  code: string
  amount: string
  onCopyCodeFailed?: () => void
  onCopyCodeSuccess?: () => void
}

const locale = {
  en: {
    cvs: {
      code: 'Payment Code',
      amount: 'Payment Amount',
      deadline: 'Payment Deadline',
    },
    vacc: {
      code: 'Account',
      amount: 'Transfer Amount',
      bank_code: 'Bank Code',
      deadline: 'Transfer Deadline',
    },
    bnpl: {
      amount: 'Transfer Amount',
    },
  },
  'zh-TW': {
    cvs: {
      code: '繳費代碼',
      amount: '繳費金額',
      deadline: '繳款期限',
    },
    vacc: {
      code: '帳號',
      amount: '應付款金額',
      bank_code: '銀行代碼',
      deadline: '繳款期限',
    },
    bnpl: {
      amount: '應付款金額',
    },
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const InfoForPayment = (props: InfoForPaymentProps) => {
  const { lang, ...restProps } = props

  return (
    <LocaleProvider defaultLang={lang}>
      <Content {...restProps} />
    </LocaleProvider>
  )
}

const Content = ({
  paymentMethod,
  className,
  bankCode,
  expiryDate,
  code,
  amount,
  onCopyCodeFailed,
  onCopyCodeSuccess,
}: Omit<InfoForPaymentProps, 'lang'>) => {
  const translation = useLocaleContext()[paymentMethod.toLowerCase() as 'cvs' | 'vacc' | 'bnpl']

  const handleCopyCode = (code: string) => {
    setTextToClipboard({ text: code, onFail: onCopyCodeFailed, onSuccess: onCopyCodeSuccess })
  }

  return (
    <div className={className}>
      {bankCode && paymentMethod === 'VACC' && 'bank_code' in translation ? (
        <Row>
          <div>{translation.bank_code} </div>
          <div>{bankCode}</div>
        </Row>
      ) : null}
      {code && 'code' in translation ? (
        <Row>
          <div>{translation.code}</div>
          <div
            style={{
              color: themeVar.color.palette['primary-500'],
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleCopyCode(code)
            }}
          >
            {code}
            <Icon type='content_copy' size={16} />
          </div>
        </Row>
      ) : null}
      {amount && 'amount' in translation ? (
        <Row>
          <div>{translation.amount} </div>
          <div>{amount}</div>
        </Row>
      ) : null}
      {expiryDate && 'deadline' in translation ? (
        <Row>
          <div>{translation.deadline} </div>
          <div>{expiryDate}</div>
        </Row>
      ) : null}
    </div>
  )
}

const Row = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        lineHeight: '175%',
        fontWeight: 400,
        letterSpacing: '0.0025em',
        gap: 8,
        padding: '12px 0',
        borderBottom: `1px solid ${themeVar.color.palette['grayscale-100']}`,
      }}
    >
      {children}
    </div>
  )
}
