import { i18n } from 'next-i18next'
import { isDesktop } from 'react-device-detect'
import { toast } from '../components/toast'
import { notify } from '../utils/error-notifier'
import { setTextToClipboard } from './clipboard'
import { isNil } from 'lodash-es'

export const appendSearchParamsToUrl = ({
  url,
  params,
  shouldReplaceDuplicateParam,
}: {
  url: string
  params: Record<string, string>
  shouldReplaceDuplicateParam?: boolean
}) => {
  const urlObj = new URL(url)

  const existingParams = new URLSearchParams(urlObj.searchParams)

  for (const [key, value] of Object.entries(params)) {
    if (shouldReplaceDuplicateParam) {
      existingParams.set(key, value)
    } else {
      if (!existingParams.has(key)) {
        existingParams.append(key, value)
      }
    }
  }

  const newUrl = new URL(`${urlObj.origin}${urlObj.pathname}?${existingParams}`)

  return newUrl.href
}

export const getQueryString = ({ obj = {} }: { obj: Record<string, any> }) => {
  const querystring = Object.entries(obj)
    .filter(([key, value]) => key && !isNil(value))
    .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
    .join('&')
  return querystring?.length ? `?${querystring}` : ''
}

export const getCanonical = (uri: string) => {
  const u = new URL(uri)
  return u.origin + u.pathname
}

export const shareUrl = async ({ title, url }: { title: string; url: string }) => {
  let isShared = false
  if (navigator.share) {
    try {
      await navigator.share({ title, url })
      isShared = true
    } catch (error) {
      // if user cancels share, error will be thrown, but is expected
    }
  }
  return isShared
}

export const copyUrl = (
  url: string,
  options?: { onCopyUrlSuccess?: () => void; onCopyUrlFailure?: (err: unknown) => void },
) => {
  return setTextToClipboard({
    text: url,
    onSuccess: () => {
      toast.success({ message: i18n?.t('common.link_copied') })
      if (options?.onCopyUrlSuccess) options.onCopyUrlSuccess()
    },
    onFail: error => {
      toast.success({ message: i18n?.t('common.copy_link_failed') })
      if (options?.onCopyUrlFailure) options.onCopyUrlFailure(error)
    },
  })
}

export const shareOrCopyUrl = async ({ title, url }: { title: string; url: string }) => {
  try {
    // On desktop, The share behavior is not user-friendly, so the URL is directly copied.
    if (isDesktop) return copyUrl(url)
    // On mobile, the shared URL triggers a native bottom sheet for sharing, which is more intuitive and convenient.
    // 1. Prioritize sharing the URL.
    // 2. Fallback to copying the URL if navigator.share is not supported in the browser.
    const isShared = await shareUrl({ title, url })
    if (!isShared) return copyUrl(url)
  } catch (err) {
    notify({
      err,
      level: 'info',
      context: { key: 'shareOrCopyUrl', message: 'Failed to share or copy URL' },
      toast: {
        type: 'error',
        props: { message: 'Unable to complete the action. Please try again.' },
      },
    })
  }
}
