import { LogoJsonLd, type LogoJsonLdProps } from 'next-seo'
import { LOGO_IMG_URI } from '../../../components/Image/constants'
import { useCanonical } from '../use-canonical'

type LogoSeoProps = Partial<LogoJsonLdProps>

const LogoSeo = ({ logo = LOGO_IMG_URI, url, ...props }: LogoSeoProps) => {
  const canonical = useCanonical(url)
  return <LogoJsonLd {...props} logo={logo} url={canonical} />
}

export { LogoSeo, type LogoSeoProps }
