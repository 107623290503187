import Script from 'next/script'
import { NextRouter, useRouter } from 'next/router'
import { useEffect } from 'react'
import { createAuthInstance } from '../../../utils/auth-utils'
import { refreshGtagUserData } from './utils'

const { getUserId } = createAuthInstance()

const isGtagInited = false

type InitGtagProps = {
  onRouterReady?: (router: NextRouter) => void
}

export const InitGtag = ({ onRouterReady }: InitGtagProps) => {
  const router = useRouter()

  useEffect(() => {
    if (!isGtagInited) {
      window.gtag('config', process.env.NEXT_PUBLIC_GTAG_ID as string, {
        send_page_view: false,
        user_id: getUserId(),
        allow_enhanced_conversions: true,
        debug_mode: true,
      })
      refreshGtagUserData()
    }
  }, [])

  useEffect(() => {
    if (typeof onRouterReady === 'function' && router.isReady) {
      return onRouterReady(router)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  return (
    <>
      <Script
        id='download-gtag'
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
      />
      <Script
        id='init-gtag'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          `,
        }}
      />
    </>
  )
}
