import { SocialProfileJsonLd, type SocialProfileJsonLdProps } from 'next-seo'
import { useCanonical } from '../use-canonical'
import { SEO_TITLE } from '../constants'

type SocialProfileSeoProps = Partial<SocialProfileJsonLdProps>

const SocialProfileSeo = ({
  type = 'Organization',
  name = SEO_TITLE,
  url,
  sameAs = [],
  ...props
}: SocialProfileSeoProps) => {
  const canonical = useCanonical(url)

  return (
    <SocialProfileJsonLd
      {...props}
      type={type}
      name={name}
      url={canonical}
      sameAs={[...new Set([...sameAs, 'https://www.facebook.com/worduphelper/'])]}
    />
  )
}

export { SocialProfileSeo, type SocialProfileSeoProps }
