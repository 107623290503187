import { useRouter } from 'next/router'
import { getCanonical } from '../../utils/url'

const useCanonical = (canonical = '') => {
  const router = useRouter()
  const _canonical = canonical || getCanonical(process.env.NEXT_PUBLIC_SELF_DOMAIN + router.asPath)
  return _canonical
}

export { useCanonical }
