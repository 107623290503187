import PackageInfo from 'package.json'
import axios, { AxiosRequestConfig } from 'axios'
import { i18n } from 'next-i18next'
import { LOCALE_TW } from 'next-i18next.config'
import { toast as wuToast } from '@libs-components/index'
import { notify } from '@libs-components/utils/error-notifier'
import { GENERIC_API_ERR_MESSAGE } from '@/constants/message'
import CredentialStorage from '@/utils/credential'
import Swal from '@/utils/sweetalert'
import { isExpectedError } from './is-expected-error'

export const clientInfo = `web3/${PackageInfo.version}`

export type FireConfigType = AxiosRequestConfig & {
  errorContext?: { [key: string]: any }
  ignoreThrowError?: boolean
  errorToastMessage?: string
}

const onResponseError = async (error: any) => {
  const errorContext = error?.config?.errorContext
  const isValidErrorContext =
    Boolean(errorContext) && typeof errorContext === 'object' && !Array.isArray(errorContext)
  const shouldThrowError = !error?.config?.ignoreThrowError
  const errorToastMessage = error?.config?.errorToastMessage

  if (error.response?.status === 500) {
    Swal.fire({
      icon: 'warning',
      text: GENERIC_API_ERR_MESSAGE,
      confirmButtonText: '好',
    })
  }

  if (isExpectedError(error)) {
    errorToastMessage && wuToast.error({ message: errorToastMessage })
  } else {
    notify({
      err: error,
      context: { key: 'response error', ...(isValidErrorContext ? errorContext : {}) },
      toast: errorToastMessage
        ? { type: 'error', props: { message: errorToastMessage } }
        : undefined,
    })
  }

  return shouldThrowError ? Promise.reject(error) : undefined
}

const onResponse = (response: any) => {
  CredentialStorage.update(response)

  return response
}

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_API,
  headers: {
    'Content-Type': 'application/json',
    'Client-Info': clientInfo,
  },
})

axiosInstance.interceptors.response.use(onResponse, onResponseError)

const urlVersionPrefixRegex = /^\/?v\d+\//i
const fire = (config: FireConfigType) => {
  const headers = {
    'Client-Lang': i18n?.resolvedLanguage || LOCALE_TW,
    ...CredentialStorage.get(),
    ...(config.headers || {}),
  }
  if (config.url && urlVersionPrefixRegex.test(config.url)) {
    // portal already has a version prefix in the baseURL,
    // so we need to remove it from the config.url
    config.url = config.url.replace(urlVersionPrefixRegex, '')
  }
  return axiosInstance.request({ ...config, headers })
}

export default fire
