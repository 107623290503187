export const ADMIN_KEY = 'admin'
export const ADMIN_PRODUCTS = 'admin/products'

export const MANAGE_SCHOOLS = 'manage/schools'
export const MANAGE_PRODUCTS = 'manage/products'
export const MANAGE_MATERIALS = 'manage/materials'

export const ORG_KEY = 'orgs'
export const STUDENTS_KEY = 'students'
export const KLASSES_KEY = 'klasses'
export const COURSES_KEY = 'courses'
export const SHOP_KEY = 'shop'

export const SALES_REPORT = 'sales_report'
export const MATERIALS_KEY = 'materials'
export const Q_GROUP_KEY = 'question_group'
export const SHAREABLE_MATERIALS_KEY = 'shareable_materials'
export const LICENSABLE_MATERIALS_KEY = 'licensable_materials'
export const COMPONENT_KEY = 'component'
export const COMMENTS_KEY = 'comments'
export const CHAPTERS_KEY = 'chapters'
export const CARDS_KEY = 'cards'
export const DECKS_KEY = 'decks'
export const PRODUCTS_KEY = 'products'
export const EXAM_PAPER_KEY = 'exam_paper'
export const EXAM_ANSWERS_KEY = 'exam_answers'
export const FULL_TEST_KEY = 'full_test'
export const PRODUCT_PRICE_KEY = 'product_price'
export const USER_DATA = 'user_data'

export const WORD_INFO = 'word/info'

export const STUDY_PLAN = 'study_plan'

export const EXPECTED_ERROR_CODES: { [key: string]: string } = {
  DUPLICATED_CARD: 'duplicated_card',
}

export const EXPECTED_ERROR_DETAILS: { [key: string]: string } = {
  NEED_TO_SIGN_IN_OR_SIGN_UP: 'You need to sign in or sign up before continuing.',
}

export const EXPECTED_ERROR_MESSAGES: { [key: string]: string } = {
  304: '304',
  AxiosError: 'AxiosError',
  NETWORK_ERROR: 'Network Error',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  CHUNK_LOAD_ERROR: 'ChunkLoadError',
  REQUEST_ABORTED: 'Request aborted',
  ABORTED: 'aborted',
}
