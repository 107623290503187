import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { createContext, useContext, type PropsWithChildren } from 'react'
import { isProd } from './utils/env'

type ApiCallerConfigType<T> = AxiosRequestConfig & {
  errorContext?: { [key: string]: any }
  isIgnoreError?: boolean
  isWithAuth?: boolean
  mockData?: { data: T; status?: number }
  // @deprecated
  locale?: string
  ignoreThrowError?: boolean
  requiresLogin?: boolean
}

// (public)  For external usage
export type LibsComponentsProviderProps = {
  apiCaller: <T>(params: ApiCallerConfigType<T>) => Promise<AxiosResponse<T>>
}

const libsComponentsContext = createContext<LibsComponentsProviderProps>({
  apiCaller: () => Promise.reject(new Error('please provide apiCaller to LibsComponentsProvider')),
})

// (private) For internal libs-components
export const useLibsComponentsCtx = () => useContext(libsComponentsContext)

// (public)  For external setup
export const LibsComponentsProvider = ({
  apiCaller,
  children,
}: PropsWithChildren<LibsComponentsProviderProps>) => {
  if (!isProd && !apiCaller) {
    throw new Error('apiCaller is required')
  }

  return (
    <libsComponentsContext.Provider value={{ apiCaller }}>
      {children}
    </libsComponentsContext.Provider>
  )
}
