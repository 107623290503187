import { OrganizationJsonLd } from 'next-seo'
import { LOGO_IMG_URI } from '../../../components/Image/constants'
import { useCanonical } from '../use-canonical'
import { SEO_TITLE, SEO_DESCRIPTION } from '../constants'

const OrganizationSeo = () => {
  const canonical = useCanonical()

  return (
    <OrganizationJsonLd
      type='Corporation'
      url={canonical}
      name={SEO_TITLE}
      logo={LOGO_IMG_URI}
      legalName='聰明學習股份有限公司'
      description={SEO_DESCRIPTION}
      address={{
        streetAddress: '松德路 65 號 4 樓之 1',
        addressLocality: '信義區',
        addressRegion: '台北市',
        postalCode: '110',
        addressCountry: '台灣',
      }}
      contactPoint={[
        {
          contactType: 'customer service',
          email: 'service@wordup.com.tw',
          areaServed: '台灣',
          availableLanguage: ['中文', '英文'],
        },
      ]}
    />
  )
}

export { OrganizationSeo }
