import { Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import cookieStorage from '@/utils/cookie-storage'
import { COOKIE_KEYS } from '@/utils/cookie-storage/constants'

import { useMaterials } from '@/apis/material/queries'
import { useRedeemCodesCount } from '@/apis/redeem-code/queries'
import dynamic from 'next/dynamic'
import getStaticProps from './get-static-props'
import Intercom from '@/components/intercom'

const MaterialsList = dynamic(() => import('@/features/homepage/material-list'))
const DeckList = dynamic(() => import('@/features/homepage/deck-list'))
// Tab Actions
const DecksActions = dynamic(() => import('./components/decks-actions'))
const MaterialActions = dynamic(() => import('./components/material-actions'))

const Home = () => {
  const { t } = useTranslation()
  const { data: materials, mutate } = useMaterials({ shouldRevalidate: false })
  const { data: redeemCodeCount } = useRedeemCodesCount()

  const [tab, setTab] = useState(
    cookieStorage.get<'material' | 'decks'>(COOKIE_KEYS.CHOSEN_HOME_PAGE_TAB) ?? 'material',
  )
  const [deckModalOpen, setDeckModalOpen] = useState(false)
  const [deckModalMode, setDeckModalMode] = useState<'create' | 'edit'>('create')

  return (
    <div className='flex w-full items-center justify-center p-4'>
      <div className='mx-10 flex w-[75rem] max-w-[75rem] flex-col justify-center'>
        <div className='flex w-full items-center justify-between'>
          <Tabs
            value={tab}
            onChange={(_, newValue) => {
              cookieStorage.set({
                key: COOKIE_KEYS.CHOSEN_HOME_PAGE_TAB,
                value: newValue,
                expires: 7,
                domain: '',
              })
              setTab(newValue)
            }}
          >
            <Tab
              label={t('common.materials')}
              value={'material'}
              className='min-w-[6.25rem] font-bold'
            />
            <Tab label={t('common.decks')} value={'decks'} className='min-w-[6.25rem] font-bold' />
          </Tabs>
          <div className='text-grayscale-800 flex items-center'>
            {tab === 'material' && <MaterialActions redeemCodeCount={redeemCodeCount} />}

            {tab === 'decks' && (
              <DecksActions
                handleClick={() => {
                  setDeckModalOpen(prev => !prev)
                  setDeckModalMode('create')
                }}
              />
            )}
          </div>
        </div>
        <MaterialsList materials={materials?.data} show={tab === 'material'} mutate={mutate} />
        <DeckList
          show={tab === 'decks'}
          modalOpen={deckModalOpen}
          setModalOpen={setDeckModalOpen}
          deckModalMode={deckModalMode}
          setDeckModalMode={setDeckModalMode}
        />
      </div>
      <Intercom openIntercom />
    </div>
  )
}

export { getStaticProps }
export default Home
