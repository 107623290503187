import { EXPECTED_ERROR_CODES, EXPECTED_ERROR_DETAILS, EXPECTED_ERROR_MESSAGES } from './constants'

const isExpectedErrorMessages = (errorMessage = '') => {
  const error = typeof errorMessage === 'string' ? errorMessage : ''
  if (!error) return false
  const expectedErrorMessages = Object.values(EXPECTED_ERROR_MESSAGES)
  return expectedErrorMessages.some(message => error.includes(message))
}

export const isExpectedError = (error: any) => {
  const responseErrorCode: string | undefined = error?.response?.data?.errors?.[0]?.code
  const responseErrorDetail: string | undefined = error?.response?.data?.errors?.[0]?.detail
  const errorStatusCode: number | undefined = error?.response?.status
  const errorMessage: string | undefined = error?.message
  const shouldIgnoreMessage = isExpectedErrorMessages(errorMessage)

  const expectedError =
    (responseErrorCode && Object.values(EXPECTED_ERROR_CODES).includes(responseErrorCode)) ||
    (responseErrorDetail && Object.values(EXPECTED_ERROR_DETAILS).includes(responseErrorDetail)) ||
    errorStatusCode === 404 ||
    shouldIgnoreMessage

  return expectedError
}
