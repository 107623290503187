import React from 'react'
import { TFunction } from 'next-i18next'
import { ProductType } from '../../types'
import themeVar from '../../theme/themeVar'
import Progressbar from '../progressbar'
import { Icon } from '../icon'
import { getProgressPercentage } from './utils'
import { CountdownDesc } from './components/countdown-desc'
import ProgressLeftText from './components/progress-left-text'
import ProgressRightText from './components/progress-right-text'

const COLORS = themeVar.color.palette

type PromotionCountdownPropTypes = {
  t: TFunction
  countdown: ProductType.DataAttributes['countdown']
  title?: React.ReactNode
  countdownTitle?: React.ReactNode
  children?: React.ReactNode
}

const progressAllowedSet = new Set<ProductType.CountdownStylesType>([
  'limited_qty_offer',
  'crowdfunding',
])

const isProgressVisible = (
  countdown: ProductType.DataAttributes['countdown'],
): countdown is Extract<
  ProductType.ProductCountdownType,
  { currentCount: number; totalCount: number }
> => {
  return countdown ? progressAllowedSet.has(countdown.style) : false
}

export const PromotionCountdown = ({
  countdown,
  title,
  countdownTitle,
  children,
  t,
}: PromotionCountdownPropTypes) => {
  const progressProps = isProgressVisible(countdown)
    ? {
        currentBuyerCount: Number(countdown.currentCount),
        goalBuyerCount: Number(countdown.totalCount),
        style: countdown.style,
        t,
      }
    : undefined

  const isLimitedQuantitySoldOut =
    countdown &&
    countdown.style === 'limited_qty_offer' &&
    countdown.currentCount &&
    countdown.totalCount &&
    countdown.currentCount >= countdown.totalCount

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {title && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '0.5rem',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: '24.5px',
            letterSpacing: '0.042px',
            color: COLORS['peach-500'],
          }}
        >
          <Icon type='directions_run' />
          {title}
        </div>
      )}

      {progressProps && (
        <div
          style={{
            marginBottom: '5px',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ProgressLeftText {...progressProps} t={t} />

            <ProgressRightText {...progressProps} t={t} />
          </div>

          <Progressbar
            bgColor={isLimitedQuantitySoldOut ? 'peach-300' : 'peach-500'}
            percentage={getProgressPercentage({
              currentBuyerCount: progressProps.currentBuyerCount,
              goalBuyerCount: progressProps.goalBuyerCount,
            })}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.75rem',
          width: '100%',
          minHeight: '1.75rem',
        }}
      >
        <CountdownDesc countdownTitle={countdownTitle}>{children}</CountdownDesc>
      </div>
    </div>
  )
}
