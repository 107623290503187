import { isAppWebview } from '../../../utils/device'

import { createAuthInstance } from '../../../utils/auth-utils'
import { getAnonymousId } from '../utils'

const authUtils = createAuthInstance()

const getGtagBasicEventParams = () => {
  return {
    wu_anon_id: getAnonymousId(),
    user_id: authUtils.getUserId(),
    external_id: authUtils.getUserId(),
    is_app: isAppWebview(),
  }
}

const refreshGtagUserData = () => {
  if (typeof gtag !== 'function') return
  gtag('set', 'user_data', {
    is_app: isAppWebview(),
    user_id: authUtils.getUserId(),
    email: authUtils.getUserEmail(),
    wu_anon_id: getAnonymousId(),
  })
}

export { getGtagBasicEventParams, refreshGtagUserData }
