import { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'

import {
  GetAllMaterialsResponse,
  GetAttachmentResponse,
  GetMaterialCourseResponse,
  GetMaterialResponse,
  GetStudentAccessResponse,
  NewCoursePayload,
  UpdateCoursePayload,
  UpdateMaterialPayload,
} from '@/typings/api'
import fire from '../fire'

const materialService = {
  getAll: () =>
    fire({ method: 'get', url: '/materials' }) as Promise<AxiosResponse<GetAllMaterialsResponse>>,
  get: (id: string, orgId?: string) =>
    fire({
      method: 'get',
      url: `/materials/${id}`,
      params: {
        org_id: orgId,
      },
    }) as Promise<AxiosResponse<GetMaterialResponse>>,
  getCourse: (id: string) =>
    fire({ method: 'get', url: `/courses/${id}` }) as Promise<
      AxiosResponse<GetMaterialCourseResponse>
    >,
  getCourseCsv: (courseId: string) =>
    fire({ method: 'get', url: `/courses/${courseId}/students.csv` }),
  getCourseStudent: ({ courseId, email }: { courseId: string; email: string }) =>
    fire({
      method: 'get',
      url: `/courses/${courseId}/students`,
      params: { email },
    }) as Promise<AxiosResponse<GetStudentAccessResponse>>,
  del: (id: string) =>
    fire({ method: 'delete', url: `/materials/${id}` }) as Promise<AxiosResponse<{}>>,
  update: (id: string, payload: UpdateMaterialPayload) =>
    fire({ method: 'patch', url: `/materials/${id}`, data: payload }),
  updateMaterial: ({ materialId, payload }: { materialId: string; payload: UpdateCoursePayload }) =>
    fire({
      method: 'patch',
      url: `/courses/${materialId}`,
      // @ts-expect-error -- temporarily mocked to expect an error because this is a generic type error after the library upgrade(snakecaseKeys and camelcaseKeys), but we still need to fix it
      data: snakecaseKeys(payload, { deep: true }),
    }),
  deleteMaterial: ({ orgId, materialIds }: { orgId: string; materialIds: string[] }) =>
    fire({
      method: 'delete',
      url: `/orgs/${orgId}/materials`,
      data: materialIds
        ? {
            material_ids: materialIds,
          }
        : undefined,
    }) as Promise<AxiosResponse<{}>>,
  createMaterial: (payload: NewCoursePayload) =>
    fire({ method: 'post', url: '/courses', data: payload }),
  getMaterialComponentAttachment: (materialComponentId: string) =>
    fire({
      method: 'get',
      url: `/attachments`,
      params: {
        attachable_type: 'MaterialComponent',
        attachable_id: materialComponentId,
      },
    }) as Promise<AxiosResponse<GetAttachmentResponse>>,
  getMaterialsEssential: (materialId: string) => ({
    method: 'get',
    url: `/materials/${materialId}/essential`,
  }),
  getMaterialsPasses: (materialId: string) => ({
    method: 'get',
    url: `/materials/${materialId}/passes`,
  }),
  getMaterialsAchievements: ({
    materialId,
    levelId,
    unreadOnly,
  }: {
    materialId: string
    levelId: string
    unreadOnly?: boolean
  }) => ({
    method: 'get',
    url: `/materials/${materialId}/achievements`,
    params: {
      level_id: levelId,
      unread_only: unreadOnly,
    },
  }),
  updateMaterialsAchievements: ({
    materialId,
    markAsReadIds,
  }: {
    materialId: string
    markAsReadIds: string[]
  }) =>
    fire({
      method: 'patch',
      url: `/materials/${materialId}/achievements`,
      data: {
        meta: {
          mark_as_read: markAsReadIds,
        },
      },
    }),
}

export default materialService
