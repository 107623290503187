import { fetchStrapiDataWithKey, StrapiProps } from '../utils/strapi'
import camelcaseKeys from 'camelcase-keys'
import useSWR from 'swr'

export const useSwrStrapi = (props: StrapiProps) => {
  const { data, isValidating } = useSWR(props, async props => {
    const data = await fetchStrapiDataWithKey(props)
    return camelcaseKeys(data, { deep: true })
  })

  return { strapiData: data?.attributes, isValidating }
}
