import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { Text } from '../text'
import constructLocale, { LangType } from '../../utils/locale-constructor'
import themeVar from '../../theme/themeVar'

const locale = {
  en: {
    highlights: {
      title: 'You will learn in this course',
    },
    suitable_for: {
      title: 'This material is suitable for',
    },
  },
  'zh-TW': {
    highlights: {
      title: '上完課你會學到',
    },
    suitable_for: {
      title: '本教材適合',
    },
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export interface SuitableForComProps {
  highlights: { type: string; text: string }[]
  suitableFor: { title: string; description: string }[]
  lang: LangType
}

export const SuitableForBlockComp = ({ suitableFor, highlights, lang }: SuitableForComProps) => {
  const translations = useLocaleContext()
  const processedHighlights = useMemo(() => {
    if (!highlights?.length) return []

    const temp: { title: string; descriptions: string[] }[] = []
    for (const highlight of highlights) {
      if (highlight.type === 'header') {
        temp.push({ title: highlight.text, descriptions: [] })
      } else {
        temp.at(-1)?.descriptions?.push?.(highlight.text)
      }
    }
    return temp
  }, [highlights])

  return (
    <LocaleProvider defaultLang={lang}>
      {processedHighlights.length > 0 && processedHighlights.some(highlight => highlight.title) ? (
        <div className='mb-8'>
          <Text component='h5' variant='heading_m_500' className='mb-3'>
            {translations.highlights.title}
          </Text>
          <ul className='m-0 flex flex-col gap-y-3 p-0'>
            {processedHighlights.map(({ title, descriptions }, index) => {
              return <Item key={title} index={index} title={title} descriptions={descriptions} />
            })}
          </ul>
        </div>
      ) : null}
      {suitableFor?.length > 0 ? (
        <div>
          <Text component='h5' variant='heading_m_500' className='mb-3 !text-[1.25rem]'>
            {translations.suitable_for.title}
          </Text>
          <ul className='m-0 flex flex-col gap-y-3 p-0'>
            {suitableFor.map(({ title, description }, index) => (
              <Item key={title} index={index} title={title} descriptions={[description]} />
            ))}
          </ul>
        </div>
      ) : null}
    </LocaleProvider>
  )
}

function Item({
  index,
  title,
  descriptions,
}: {
  index: number
  title: string
  descriptions: string[]
}) {
  return (
    <li style={{ display: 'flex', gap: '0.5rem' }}>
      {title ? (
        <Text
          variant='body_s_400'
          style={{
            backgroundColor: themeVar.color.palette['grayscale-50'],
            display: 'flex',
            height: '1.5rem',
            width: '1.5rem',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '9999px',
            marginTop: '0.125rem',
            flexShrink: 0,
          }}
          className='pb-[2px] !text-[1rem]'
        >
          {index + 1}
        </Text>
      ) : null}
      <div>
        <Text variant='body_m_500' component='h5' className='mb-1 !text-[1rem]'>
          {title}
        </Text>
        {descriptions
          .filter(description => description)
          .map(description => (
            <Text
              key={description}
              component='p'
              variant='body_s_400'
              className='mb-2 flex gap-x-2 !text-[14px] last:mb-0'
            >
              <div className='mt-[11.5px] h-1 w-1 flex-shrink-0 rounded-full bg-black' />
              {description}
            </Text>
          ))}
      </div>
    </li>
  )
}

export const Description = styled.div`
  padding-left: ${themeVar.distance.ml};
  padding-top: ${themeVar.distance.xs};
  font-size: ${themeVar.fontSize.sm};
  line-height: 1.5;
`
