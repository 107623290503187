import { BrandJsonLd, type BrandJsonLdProps } from 'next-seo'
import { LOGO_IMG_URI } from '../../../components/Image/constants'
import { useCanonical } from '../use-canonical'
import { SEO_DESCRIPTION } from '../constants'

type BrandSeoProps = Partial<BrandJsonLdProps>

const BrandSeo = ({ id, ...props }: BrandSeoProps) => {
  const canonical = useCanonical(id)

  return <BrandJsonLd slogan={SEO_DESCRIPTION} logo={LOGO_IMG_URI} {...props} id={canonical} />
}

export { BrandSeo, type BrandSeoProps }
