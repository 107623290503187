import { NextSeo, type NextSeoProps } from 'next-seo'
import { isProd } from '../../../utils/env'
import { useCanonical } from '../use-canonical'

type NextSeoImplProps = NextSeoProps

const NextSeoImpl = ({
  noindex = !isProd,
  nofollow = !isProd,
  canonical,
  ...props
}: NextSeoImplProps) => {
  const _canonical = useCanonical(canonical)

  return <NextSeo {...props} noindex={noindex} nofollow={nofollow} canonical={_canonical} />
}

export { NextSeoImpl, type NextSeoImplProps }
