import { SignInFormData, SignUpFormData } from '.'
import { AuthType } from './types'
import { useForm } from 'react-hook-form'
import { Text } from '../text'
import { Icon } from '../icon'
import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import { useLocale } from './local-provider'

const defaultValues = {
  email: '',
  password: '',
  showPassword: false,
}

type PropTypes = {
  handleAuth: (data: SignInFormData | SignUpFormData) => void
  setLayout: (mode: AuthType) => void
}

const SignInForm = ({ handleAuth, setLayout }: PropTypes) => {
  const { signin, error, loading, password, email, forgetPassword } = useLocale()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues })
  const showPassword = watch('showPassword')

  return (
    <form onSubmit={handleSubmit(handleAuth)}>
      <FormControl isInvalid={Boolean(errors.email)}>
        <Input
          type='email'
          placeholder={email.plhr}
          {...register('email', {
            required: error.required,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: error.email || '',
            },
          })}
        />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.password)} mb='0.5rem'>
        <Input
          type={showPassword ? 'text' : 'password'}
          placeholder={password.plhr}
          {...register('password', {
            required: error.required,
            minLength: { value: 6, message: error.password || '' },
          })}
        />
        <Icon
          type={showPassword ? 'visibility' : 'visibility_off'}
          color='grayscale-500'
          className='absolute right-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer'
          onClick={e => {
            e.preventDefault()
            setValue('showPassword', !showPassword)
          }}
        />
      </FormControl>
      {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}

      <button
        type='button'
        onClick={() => setLayout('forget-password')}
        style={{
          padding: 0,
          border: 'none',
          outline: 'none',
          background: 'none',
          cursor: 'pointer',
          marginBottom: '1rem',
        }}
      >
        <Text variant='body_s_400' color='grayscale-400'>
          {forgetPassword.title}
        </Text>
      </button>

      <Button type='submit' disabled={isSubmitting}>
        {isSubmitting ? loading : signin.submit}
      </Button>
    </form>
  )
}

export default SignInForm
