import { BreadcrumbJsonLd, type BreadCrumbJsonLdProps } from 'next-seo'
import { SEO_TITLE } from '../constants'

type BreadcrumbSeoProps = BreadCrumbJsonLdProps

const BreadcrumbSeo = ({ itemListElements = [], ...props }: BreadcrumbSeoProps) => {
  return (
    <BreadcrumbJsonLd
      {...props}
      itemListElements={[
        {
          position: 1,
          name: SEO_TITLE,
          item: process.env.NEXT_PUBLIC_SELF_DOMAIN,
        },
        ...itemListElements
          // Filter out the shop breadcrumb SEO element if it exists
          .filter(element => {
            if (element.item === process.env.NEXT_PUBLIC_SELF_DOMAIN) return false
            if (element.name === SEO_TITLE) return false
            return true
          })
          // Update the position value based on the array index, starting from 2
          .map((element, index) => ({ ...element, position: index + 2 })),
      ]}
    />
  )
}

export { BreadcrumbSeo, type BreadcrumbSeoProps }
