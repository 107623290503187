import { wrapDeprecatedConsole } from '@libs-components/utils/console'
import notifier from '@libs-components/utils/error-notifier'

/** @deprecated The notify was moved from `@/utils/error-notifier` to `@libs-components/utils/error-notifier`. */
export const notify = wrapDeprecatedConsole(notifier.notify, [
  "You should use `import notifier from '@libs-components/utils/error-notifier'`",
  "or `import { notify } from '@libs-components/utils/error-notifier'`",
])

/** @deprecated The setUser was moved from `@/utils/error-notifier` to `@libs-components/utils/error-notifier`. */
export const setUser = wrapDeprecatedConsole(notifier.setUser, [
  "You should use `import notifier from '@libs-components/utils/error-notifier'`",
  "or `import { setUser } from '@libs-components/utils/error-notifier'`",
])

/** @deprecated The unsetUser was moved from `@/utils/error-notifier` to `@libs-components/utils/error-notifier`. */
export const unsetUser = wrapDeprecatedConsole(notifier.unsetUser, [
  "You should use `import notifier from '@libs-components/utils/error-notifier'`",
  "or `import { unsetUser } from '@libs-components/utils/error-notifier'`",
])

/** @deprecated The isNotifiableError was moved from `@/utils/error-notifier` to `@libs-components/utils/error-notifier`. */
export const isNotifiableError = wrapDeprecatedConsole(notifier.isNotifiableError, [
  "You should use `import notifier from '@libs-components/utils/error-notifier'`",
  "or `import { isNotifiableError } from '@libs-components/utils/error-notifier'`",
])

/** @deprecated The notifier was moved from `@/utils/error-notifier` to `@libs-components/utils/error-notifier`. */
export default notifier
