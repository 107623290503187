import { WebPageJsonLd, type WebPageJsonLdProps } from 'next-seo'
import { useCanonical } from '../use-canonical'

type WebPageSeoProps = Partial<WebPageJsonLdProps>

const WebPageSeo = ({ id, ...props }: WebPageSeoProps) => {
  const canonical = useCanonical(id)

  return (
    <WebPageJsonLd
      lastReviewed={new Date().toISOString()}
      reviewedBy={{ type: 'Organization', name: '聰明學習股份有限公司' }}
      {...props}
      id={canonical}
    />
  )
}

export { WebPageSeo, type WebPageSeoProps }
