import { Stage } from '../../env'

interface LocalStorage {
  get: <T>(
    key: string,
    isJson?: boolean,
    isStageKey?: boolean,
  ) => T extends undefined ? string | Record<string, string> | null : T
  set: (key: string, data: string) => void
  remove: (key: string) => void
  clearAll: (cb?: any) => void
}

const isBrowser = typeof window !== 'undefined'

const createInstance = (stage: Stage): LocalStorage => {
  const stagedKey = (key: string) => `${stage !== 'production' ? `${stage}-` : ''}${key}`

  return {
    get: (key, isJson = false, isStageKey = true) => {
      if (!isBrowser) return null
      const value = localStorage.getItem(isStageKey ? stagedKey(key) : key)
      if (!value) return null
      if (isJson) return JSON.parse(value)
      return value
    },
    set: (key, data) => {
      if (!isBrowser) return
      localStorage.setItem(stagedKey(key), data)
    },
    remove: key => {
      if (!isBrowser) return
      localStorage.removeItem(stagedKey(key))
    },
    clearAll: cb => {
      if (!isBrowser) return
      localStorage.clear()

      if (typeof cb === 'function') {
        setTimeout(() => {
          cb()
        }, 500)
      }
    },
  }
}

export default createInstance
