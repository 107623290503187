import type { MaterialWithRedeemCodesType, Web2UnusedRedeemCodes } from './dataType'

type ArrayElementType<ArrayType extends unknown[]> = ArrayType extends (infer ElementType)[]
  ? ElementType
  : never

const Transformer = (redeemCodes: Web2UnusedRedeemCodes) => {
  const transformedDict: {
    [materialId: string]: MaterialWithRedeemCodesType
  } = {}

  const groupByMaterialId = redeemCodes.reduce((acc, redeemCode) => {
    const attributes = redeemCode.attributes
    const materialId = attributes.redeemableId

    if (!acc[materialId]) {
      acc[materialId] = {
        materialId,
        productTitle: attributes.redeemableName,
        imageUrl: attributes.redeemableImageUrl,
        codes: [],
      }
    }

    acc[materialId].codes.push({
      code: attributes.code,
      useUp: attributes.useUp,
      canIRedeem: attributes.canIRedeem,
      materialExpiryKind: attributes.materialExpiryKind,
      materialExpiryOptions: attributes.materialExpiryOptions,
    } as ArrayElementType<MaterialWithRedeemCodesType['codes']>)

    return acc
  }, transformedDict)

  return Object.values(groupByMaterialId)
}

export default Transformer
