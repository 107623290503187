import { SignInFormData, SignUpFormData } from '.'
import { useForm } from 'react-hook-form'
import { Text } from '../text'
import { Icon } from '../icon'
import themeVar from '../../theme/themeVar'
import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import EmailInput from './email-input'
import { useLocale } from './local-provider'

const defaultValues = {
  name: '',
  email: '',
  password: '',
  showPassword: false,
}

type PropTypes = {
  termUrl?: string
  handleAuth: (data: SignInFormData | SignUpFormData) => void
}

const SignUpForm = ({ termUrl, handleAuth }: PropTypes) => {
  const { name, password, signup, error, loading, privacy } = useLocale()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues })
  const showPassword = watch('showPassword')

  return (
    <form onSubmit={handleSubmit(handleAuth)}>
      <FormControl isInvalid={Boolean(errors.name)}>
        <Input placeholder={name.plhr} {...register('name', { required: error.required })} />
      </FormControl>
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.email)}>
        <EmailInput register={register} />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.password)} mb='1.25rem'>
        <Input
          type={showPassword ? 'text' : 'password'}
          placeholder={password.plhr}
          {...register('password', {
            required: error.required,
            minLength: { value: 6, message: error.password || '' },
          })}
        />
        <Icon
          type={showPassword ? 'visibility' : 'visibility_off'}
          color='grayscale-500'
          className='absolute right-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer'
          onClick={e => {
            e.preventDefault()
            setValue('showPassword', !showPassword)
          }}
        />
      </FormControl>
      {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}

      <Button type='submit' disabled={isSubmitting} style={{ marginBottom: '0.25rem' }}>
        {isSubmitting ? loading : signup.submit}
      </Button>

      {termUrl && (
        <Text component='p' variant='label_s_400'>
          {privacy.message}{' '}
          <a
            href={termUrl}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: themeVar.color.palette['primary-500'] }}
          >
            {privacy?.linkText}
          </a>
        </Text>
      )}
    </form>
  )
}

export default SignUpForm
