import { ReactNode } from 'react'
import { toast as _toast, ToastContent, ToastOptions } from 'react-toastify'
import { Icon } from '../icon'
import { SuccessfullyAddCartToastContent } from './toast-contents'
import 'react-toastify/dist/ReactToastify.css'

type ToastOptionsType = ToastOptions<{}> | undefined

export const WuToastTypes = ['success', 'error', 'info', 'warning'] as const

export type WuToastProps = {
  message: ToastContent
  options?: ToastOptionsType
  actionComponent?: ReactNode
}

const MessageComp = ({
  message,
  actionComponent,
}: {
  message: ToastContent
  actionComponent?: ReactNode
}) => {
  return (
    <div className='text-grayscale-800 flex items-center justify-between text-xs font-medium leading-4'>
      {message as ReactNode}
      {actionComponent}
    </div>
  )
}

export const toast = {
  success: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.success(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: () => <Icon type='check_circle' color='green-500' size={20} />,
    })
  },
  error: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.error(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon type='dangerous' color='peach-500' size={20} />,
    })
  },
  info: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.info(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon type='info' color='grayscale-300' size={20} />,
    })
  },
  warning: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.warning(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon type='emergency_home' color='yellow-500' size={20} />,
    })
  },
  addToCartSuccess: () => {
    const options: ToastOptionsType = window.matchMedia(`(min-width: 768px)`).matches
      ? {}
      : { position: 'top-right' }
    _toast.success(<SuccessfullyAddCartToastContent />, {
      ...options,
      icon: <Icon type='shopping_cart' color='green-500' size={20} />,
    })
  },
}
