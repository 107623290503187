const collectionTypes = {
  SIDE_CONFIGS: 'site-configs',
  B2B_CLIENTS: 'b2b-clients',
} as const

export type StrapiProps = {
  strapiApiDomain?: string
  targetValue: string
  targetKey: string
  collectionType: (typeof collectionTypes)[keyof typeof collectionTypes]
}

export const fetchStrapiDataWithKey = async ({
  strapiApiDomain,
  targetKey,
  targetValue,
  collectionType,
}: StrapiProps) => {
  try {
    const response = await fetch(
      `${strapiApiDomain}/api/${collectionType}?filters[${targetKey}]=${targetValue}`
    )

    if (!response.ok) throw new Error('strapi fetch error')

    const { data } = await response.json()

    return data?.[0]
  } catch {
    return null
  }
}
