import { createCookieStorage } from '../../utils/storage'
import { STAGE, Stage } from '../../utils/env'

const CookieLib = createCookieStorage(STAGE as Stage)

export const ANONYMOUS_ID_COOKIE_KEY = '_wuAnonId'
export const ANONYMOUS_EXPIRY_TIME = 730

const makeId = () => {
  if (typeof window !== 'object') return ''

  // this format is referenced to GA cookie
  // https://www.bounteous.com/insights/2017/08/17/how-google-analytics-uses-cookies-identify-users/
  const version = '1'
  const countOfDot = ((window.location.hostname || '').match(/[.]/) || []).length
  const random8Digits = Math.random() * 100000000
  const utcTimestampS = Date.now() / 1000

  return `WU${version}-${countOfDot}-${random8Digits}-${utcTimestampS}`
}

const setAnonymousId = () => {
  if (typeof window !== 'object') return ''

  const existedAnonId = CookieLib.get(ANONYMOUS_ID_COOKIE_KEY)
  if (existedAnonId) return existedAnonId

  const id = makeId()

  CookieLib.set({
    key: ANONYMOUS_ID_COOKIE_KEY,
    value: id,
    expires: 730,
    domain: process.env.NEXT_PUBLIC_ROOT_DOMAIN!,
  })

  return id
}

const getAnonymousId = () => {
  if (typeof window !== 'object') return null

  let id = CookieLib.get(ANONYMOUS_ID_COOKIE_KEY)
  if (!id) {
    id = setAnonymousId()
  }
  return id
}

export { getAnonymousId, setAnonymousId }
